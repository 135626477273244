// Import React and CSS files
import React, {useEffect} from 'react';
import './page-layout.css';
import './menu.css';
import './document-layout.css';
import './document-text.css';
import './document-view-style.css';
import './built-in-view.css';
import './ide.css';
import './loader.css';

const DeleteAccountLogin = () => {

  useEffect(() => {
    // Redirect to the authentication server URL
    window.location.href = "https://gyropole.auth.eu-central-1.amazoncognito.com/login?client_id=6ffpv46jigr2g8fcvikj64toms&response_type=code&scope=email+openid&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fdelete-account";
  }, []);

  return (
    <div></div>
  );

};

export default DeleteAccountLogin;
