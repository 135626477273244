/*index.js*/

import React, { StrictMode } from "react";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { createRoot } from "react-dom/client";

import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from './LoginPage.js';
import HomePage from './HomePage.js';
import OAuthCallback from "./OAuthCallback";
import Application from "./application";
import PrivacyPolicy from "./privacy_policy_tr.js";
import DeleteAccountLogin from "./DeleteAccountLogin.js";
import DeleteAccount from "./DeleteAccount.js";
import Terms from "./terms_of_use_tr.js";

const root = createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="1019191398193-ol6v1k85faaoaf5t5k8sq1hb68vlupcn.apps.googleusercontent.com">
    <StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path='/callback' element={<OAuthCallback/>}/>
          <Route path='/dev-login' element={<LoginPage/>}/>
          <Route path='/delete-account-login' element={<DeleteAccountLogin/>}/>
          <Route path='/delete-account' element={<DeleteAccount/>}/>
          <Route path='/' element={<Application/>}/>
          <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
          <Route path='/terms-of-use' element={<Terms/>}/>
          <Route path='/home' element={<HomePage/>}/>
        </Routes>
      </BrowserRouter>
    </StrictMode>
  </GoogleOAuthProvider>
);