// Import React and CSS files
import React from 'react';
import './page-layout.css';
import './menu.css';
import './document-layout.css';
import './document-text.css';
import './document-view-style.css';
import './built-in-view.css';
import './ide.css';
import './loader.css';
import logoTextSvg from './wetaboo_text.svg';
import briketLogo from './logo-text.svg';
import wetabooLogo from './wetaboo_logo.png';
import Button from 'react-bootstrap/Button';
import {Col, Form, InputGroup, Nav, Navbar, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Footer from "./footer";
import { useNavigate } from 'react-router-dom';
import Header from "./header";

const Application = () => {

  return (
    <div className="main-container">

      <div className="up-container" style={{ backgroundColor: '#FF0000' }}>
        <img src={logoTextSvg} alt="Logo" className="logo" style={{ padding: '3%' }} />
        <div className="menu-button"></div>
      </div>

      <div className="down-container" style={{ backgroundColor: '#FFFFFF' }}>
        <div className="down-left-container"></div>

        <div className="down-middle-container">
          <div className="down-middle-badge-container large-text" style={{ textAlign: 'center' }}>
            <a href="https://play.google.com/store/apps/details?id=com.gyropole.wetaboo&pcampaignid=web_share">
              <img className="google-play-badge" alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" />
            </a>
          </div>

        </div>

        <div className="down-right-container"></div>

      </div>

      <div className="up-container" style={{ backgroundColor: '#00FF68' }}>
        <img src={briketLogo} alt="Logo" className="logo" />
        <div className="menu-button"></div>
      </div>

      <div className="down-container" style={{ backgroundColor: '#ffffff' }}>
        <div className="down-left-container"></div>

        <div className="down-middle-container">
          <div className="down-middle-badge-container large-text" style={{ textAlign: 'center' }}>
            <a href="https://play.google.com/store/apps/details?id=com.kastrakomnen.hmessenger">
              <img className="google-play-badge" alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" />
            </a>
          </div>

        </div>

        <div className="down-right-container"></div>

      </div>

      <Footer/>

    </div>

  );
};

export default Application;
