import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


function OAuthCallbackComponent() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {

    const queryParams = new URLSearchParams(window.location.search);
    const _code = queryParams.get('code');

    if (_code) {

      const url = 'https://mark-1.auth.eu-central-1.amazoncognito.com/oauth2/token';

      const requestBody = {
        grant_type: 'authorization_code',
        code: _code,
        redirect_uri: 'https://www.gyropole.com/callback',
        client_id: '3eb2b8ij4e2ojr3jiui6es2ahq',
      };

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(requestBody).toString(),
      })
        .then(response => response.json())
        .then(data => {

          localStorage.setItem('idToken', data.id_token);
          navigate('/home')

          // Handle the response data, which may include access tokens and more
          console.log('Response:', data);
        })
        .catch(error => {
          // Handle any errors that occur during the request
          console.error('Error:', error);
        });


    } else {

    }

  }, []);

  return (
    <div></div>
  );
}

export default OAuthCallbackComponent;
