import React from 'react';
import {
  MDBFooter,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBCol,
  MDBRow,
  MDBBtn
} from 'mdb-react-ui-kit';
import {useNavigate} from "react-router-dom";

function Footer() {

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/privacy-policy', {replace: false})
  };

  const handleTerms = () => {
    navigate('/terms-of-use', {replace: false})
  };

  const handleDeleteAccount = () => {
    navigate('/delete-account-login', {replace: false})
  }

  const navigateToDevLogin = () => {
    navigate('/dev-login', {replace: false})
  };

  return (
    <MDBFooter className='text-center' color='white' bgColor='dark'>
      <MDBContainer className='p-4'>

        <section className=''>
          <form action=''>
            <MDBRow className='d-flex justify-content-center'>
              <MDBCol size="auto">
                <p className='pt-2'>
                  <strong>Bültenimize abone olun</strong>
                </p>
              </MDBCol>

              <MDBCol md='5' start>
                <MDBInput contrast type='email' label='E-Posta Adresi' className='mb-4' />
              </MDBCol>

              <MDBCol size="auto">
                <MDBBtn outline color='light' type='submit' className='mb-4'>
                  Abone Ol
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </form>
        </section>

        <section className=''>
          <MDBRow>
            <MDBCol>
              <h5 className=''>Dev</h5>

              <ul className='list-unstyled mb-0'>
                <li>
                  <p onClick={navigateToDevLogin} className='text-white'>
                    Giriş
                  </p>
                </li>
              </ul>
            </MDBCol>

            <MDBCol>
              <h5 className=''>Destek</h5>

              <ul className='list-unstyled mb-0'>
                <li>
                  <p onClick={handleDeleteAccount} className='text-white'>
                    Hesap Silme
                  </p>
                </li>
                <li>
                  <p onClick={handleButtonClick} className='text-white'>
                    Gizlilik Sözleşmesi
                  </p>
                </li>
                <li>
                  <p onClick={handleTerms} className='text-white'>
                    Kullanım Koşulları
                  </p>
                </li>
              </ul>
            </MDBCol>

          </MDBRow>
        </section>
      </MDBContainer>

      <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        © 2024 Gyropole
      </div>
    </MDBFooter>
  );
}

export default Footer;