import fonts from './font.css';

function PrivacyPolicyTr() {
  return (
    <div>
      <div className="page">
        <div className="translations-content-container">
          <div className="container">
            <div className="myClass tab-content translations-content-item en visible" id="en">

              <br/>

              <h1>Kullanım Koşulları</h1>
              <p>Son Güncelleme: 30 Mart 2024</p>

              <br/>

              <h2>Giriş</h2>

              <p>

                Bu kullanım koşulları (Koşullar), sizin ve Wetaboo, Briket ("biz" veya "bizim") oyun ve hizmetlerimizi kullanımınızla ilgili ilişkiyi belirler.
                Belirli kelimelerin ve ifadelerin anlamı için lütfen aşağıdaki Tanımlar bölümüne bakınız.
                Hizmetlerimizi indirerek, oynayarak veya erişerek bu Koşulları ve Gizlilik Sözleşmesinin bu Koşulların bir parçasını oluşturduğunu kabul etmiş olursunuz.
                Lütfen bu Koşulları okuyun. Eğer bu Koşulları anlamıyorsanız veya bunların herhangi bir kısmını kabul etmiyorsanız, o zaman Hizmetlerimizi kullanmamalısınız veya satın almamalısınız. Hizmetlerimizi yükleyerek, kullanarak veya erişerek, Koşullarımızı ve Gizlilik Politikamızı kabul etmiş olursunuz.
                Eğer bir çocuk Hizmetlerimizi kullanmak istiyorsa, çocuğun yasal vasisi, çocuğun kullanımının bir önkoşulu olarak bu Koşulları kabul eder.
                Bu Koşullara her zaman gyropole.com adresinden erişebilirsiniz. Biz, değiştirilmiş koşulları gyropole.com adresinde yayınlamak suretiyle Koşullarımızı ve Gizlilik Politikamızı tek taraflı olarak kısmen veya tamamen değiştirme, ekleme veya kaldırma hakkını saklı tutarız. Hizmetlerimizi kullanmaya devam etmeniz, güncellenmiş Koşulları ve Gizlilik Politikasını kabul ettiğinizi doğrular.

              </p>

              <br/>

              <h2>Hizmetlerimizi Kullanma Hakkı</h2>

              <p>

                <ul>
                  <li>
                    Bu Şartlara tabi olarak, Biz Size münhasır olmayan, devredilemeyen, alt lisanslama yapılamayan, sınırlı bir hizmet kullanma hakkı tanırız. Hizmetlerimizi başka bir amaçla kullanmamayı kabul edersiniz. Size verilen haklar, bu Koşullara tam uymanız şartına tabidir.
                  </li>
                  <li>
                    Hizmetlere ilişkin tüm hak, unvan ve menfaatler tarafımıza aittir. Bu, tüm telif haklarının, ticari markaların, kodların, yazılımların, karakterlerin, temaların, karakter adlarının, oyun adlarının, hikayelerin, diyalogların, ayarların, çizimlerin, ses efektlerinin, müziğin, uygulama içi öğelerin, oyun kayıtlarının, ticari sırların ve Hizmetlere ilişkin veya Hizmetlerden türetilen patentler, unvanlar ve tüm hakların her zaman sahibi olduğumuz anlamına gelir. Hizmetler, tamamen kendi takdirimize bağlı olarak verilen veya verilmeyen, önceden yazılı iznimiz olmaksızın hiçbir şekilde veya herhangi bir ortamda kopyalanamaz, çoğaltılamaz veya dağıtılamaz.
                  </li>
                  <li>
                    Lisans, Hizmetleri elden çıkarmanız veya bu Koşullara uygun olarak lisansı sona erdirmemizden hangisi önce gerçekleşirse sona erer.
                  </li>
                </ul>

              </p>

              <br/>

              <h2>Sanal Varlıklar</h2>

              <p>

                <ul>
                  <li>
                    Hizmetlerimiz Sanal Öğeler içerebilir. Bu öğeler oyun yoluyla kazanılabilir veya yasal para birimi veya Sağlayıcı Kredileri ile "satın alınabilir".
                  </li>
                  <li>
                    Sanal Öğelerin yalnızca Hizmetlerimiz kapsamında mevcut olduğunu ve hiçbir zaman bizden veya başkasından gerçek para, gerçek mallar veya gerçek hizmetlerle değiştirilemeyeceğini kabul edersiniz. Sanal Öğelere sahip olmazsınız sadece bunları kullanmak için sınırlı, kişisel, iptal edilebilir bir lisans satın alırsınız. Sanal Öğelerin herhangi bir bakiyesi, saklanan herhangi bir değeri yansıtmaz.
                  </li>
                  <li>
                    Herhangi bir Sanal Öğeyi bildirimde bulunmaksızın ve size karşı herhangi bir yükümlülük altına girmeksizin kontrol etme, düzenleme, değiştirme veya kaldırma hakkını saklı tutuyoruz.
                  </li>
                  <li>
                    Sanal Öğelerinizin herhangi bir "kaybı" veya "hasarından" kaynaklanan herhangi bir Kayba ilişkin olarak herhangi bir Talepte bulunma hakkınız yoktur ve bu Kayba maruz kalmazsınız. Yasaların izin verdiği ölçüde, uğradığınızı iddia ettiğiniz herhangi bir Kayıp veya hak sahibi olduğunuzu iddia ettiğiniz herhangi bir Talep dahil ancak bunlarla sınırlı olmamak üzere, Sanal Öğelerle ilgili herhangi bir konuda size tazminat ödemekle yükümlü olmayacağız.
                  </li>
                  <li>
                    Bu Koşulları ihlal etmeniz durumunda Hesabınız askıya alınır veya feshedilir, sahip olabileceğiniz tüm Sanal Öğeleri kaybedersiniz ve bu kayıp için size tazminat ödemeyiz veya size herhangi bir geri ödeme yapmayız.
                  </li>
                  <li>
                    Kişisel verilerinizin Gizlilik Bildirimi uyarınca silinmesini talep etmeniz ve gerçekleşmesi durumunda, tüm Sanal Öğelerinizi ve diğer Hesap bilgilerinizi kalıcı olarak kaybedersiniz ve herhangi bir geri ödeme hakkınız olmaz. Silindikten sonra bu tür Sanal Öğeleri artık sizinle ilişkilendiremeyiz.
                  </li>
                </ul>

              </p>

              <br/>

              <h2>Yükümlülükleriniz</h2>

              <p>
                <ul>
                  <li>
                    Hizmetlerimizi kiralamayacak, ödünç vermeyecek, satmayacak, devretmeyecek, yeniden dağıtmayacak, alt lisansını vermeyecek veya Hizmetlerimizi aynı anda birden fazla Cihaz tarafından kullanılabilecek bir ağ üzerinden kullanıma sunmayacaksınız. Hizmetlerimizi içeren herhangi bir Cihazı satarsanız veya başka şekilde elden çıkarırsanız, devrederseniz bunu yapmadan önce Hizmetlerimizin tüm kopyalarını kaldırmalısınız.
                  </li>
                  <li>
                    Hizmetlerimizi kullanımınıza müdahale etmek veya avantaj elde etmek için herhangi bir türde hile, değişiklik, istismar veya diğer yetkisiz araçları kullanmayacak veya bunların kullanımını teşvik etmeyeceksiniz.
                  </li>
                  <li>
                    Önceden yazılı rızamız olmadan Hizmetlerimizi herhangi bir ticari amaçla kullanmayacaksınız. Yukarıda belirtilenleri sınırlamaksızın, Hizmetlerimizi oyun salonları gibi kamuya açık hale getirmemeli, Hizmetlerimizi kendi ürünlerinizde veya televizyon programlarında veya filmlerde olduğu gibi içerikte kullanmamalı, Hizmetlerimizi herhangi bir şekilde herhangi bir ticari ürünün reklamında, talebinde veya iletiminde kullanmamalısınız.
                  </li>
                  <li>
                    Hizmetlerimizin veya herhangi bir altyapımızın veya diğer ticari faaliyetlerimizin normal işleyişini aksatmaya veya bunlara yük olmaya çalışmayacaksınız.
                  </li>
                  <li>
                    Hizmetlerimize yetkisiz erişim sağlamaya çalışmayacaksınız.
                  </li>
                  <li>
                    Hizmetlerimizi otomatik olarak kullanmayacaksınız.
                  </li>
                  <li>
                    Hizmetlerimizi kullanırken başka herhangi bir kişinin kimliğine bürünmeyeceksiniz.
                  </li>
                  <li>
                    Başka bir kişinin kişisel bilgilerini veya verilerini, o kişinin izni olmadan Hizmetlerimiz aracılığıyla yayınlamayacaksınız.
                  </li>
                  <li>
                    Hizmetlerimizden herhangi birini kaynak koda dönüştürmeye, tersine mühendislik yapmaya, parçalara ayırmaya veya hacklemeye ya da şifreleme teknolojilerimizi veya güvenlik önlemlerimizi veya tarafımızca iletilen, işlenen veya saklanan verileri yenmeye veya üstesinden gelmeye çalışmayacaksınız.
                  </li>
                  <li>
                    Hizmetlerimizi başka bir kişiyi taciz etmek, suistimal etmek, tehdit etmek veya zarar vermek için ya da başka bir kişiyi veya grubu taciz etmeye, istismar etmeye veya zarar vermeye teşebbüs etmek veya başkasını kışkırtmak için kullanmayacaksınız.
                  </li>
                  <li>
                    Önceden yazılı olarak kabul etmediğimiz sürece Hizmetlerimizi veya bunların herhangi bir bölümünü kopyalamayacak, dağıtmayacak, kamunun kullanımına sunmayacak veya bunlardan türev çalışmalar oluşturmayacaksınız.
                  </li>
                  <li>
                    Hizmetlerimizi, bizimle, Hizmetlerimizle veya diğer kullanıcılarla ilgili veriler veya bilgiler dahil ancak bunlarla sınırlı olmamak üzere bilgi veya verileri toplamak, biriktirmek veya başka bir şekilde birleştirmek için kullanmayacaksınız.
                  </li>
                  <li>
                    Hizmetlerimizi herhangi bir yasanın fiilen ihlal edilmesi veya ihlal edilmeye çalışılmasıyla bağlantılı olarak kullanmayacaksınız.
                  </li>
                  <li>
                    Hizmetlerimizi yüklemenizin ve kullanmanızın, internet hizmetiniz veya üçüncü taraflardan edinilen diğer hizmetler için geçerli olabilecek veri kullanım kotalarını veya diğer sınırlamaları aşmanıza neden olmamasını sağlamak sizin sorumluluğunuzdadır.
                  </li>
                  <li>
                    Hizmetlerimizi aldığınız ilgili dijital mağazanın hizmet şartlarına uymanız gerekir.
                  </li>
                  <li>
                    Hizmetlerimizi kullanırken üçüncü taraf sözleşme şartlarına uymanız gerekir.
                  </li>
                  <li>
                    Bize sağladığınız bilgiler her zaman doğru, kesin ve eksiksiz olmalıdır.
                  </li>
                </ul>
              </p>

              <br/>

              <h2>Kullanıcı etkileşimi</h2>

              <p>

                <ul>
                  <li>
                    Hizmetlerimiz aracılığıyla veya Hizmetlerimizle bağlantılı olarak yayınladığınız Kullanıcı İçeriğinden yalnızca siz sorumlusunuz.
                  </li>
                  <li>
                    Hizmetlerimiz kullanılarak oluşturulan veya gönderilen herhangi bir Kullanıcı İçeriğini (Fikri Mülkiyetiniz dahil) kullanmamız için bize telifsiz, dünya çapında, münhasır olmayan, alt lisanslanabilir bir hak verirsiniz.
                  </li>
                  <li>
                    Hizmetimizi kullanırken mevcut olan herhangi bir Kullanıcı İçeriğinin kullanımıyla ilişkili tüm risklerden siz sorumlusunuz. Hizmetlerimizin diğer kullanıcıları tarafından yapılan kullanıcı gönderilerinin veya iletimlerinin doğruluğundan veya içeriğinden sorumlu veya yükümlü değiliz ve herhangi bir Kullanıcı İçeriği ile ilgili olarak tarafınızca gerçekleştirilen herhangi bir işlem veya yaptığınız güvenden kaynaklanan herhangi bir Kayıp veya Hak Talebinden sorumlu değiliz.
                  </li>
                  <li>
                    Kullanıcı İçeriği gizli ve tescilli değildir. Gizli tutmak istediğiniz ve/veya başkalarının kullanmasını istemediğiniz bir fikriniz veya bilginiz varsa, bunu Hizmetlerimizde yayınlamayın. Göndermeyi seçtiğiniz herhangi bir fikir veya bilgi için sizi değerlendirme, kullanma veya telafi etme yükümlülüğümüz yoktur.
                  </li>
                </ul>

              </p>

              <br/>

              <h2>Fikri Mülkiyet</h2>

              <p>

                <ul>
                  <li>
                    Hizmetlerimizle ilgili tüm Fikri Mülkiyetin bize ait olduğunu ve lisansının bize ait olduğunu kabul edersiniz. Hizmetlerimiz size lisanslanmaktadır ve Siz Hizmetlerimizdeki hiçbir ünvan veya mülkiyetin Size devredilmediğini veya atanmadığını anlıyorsunuz. İş bu Koşullar Servislerimizdeki herhangi bir mülkiyetin satışı olmadığını anlıyorsunuz.
                  </li>
                  <li>
                    Hizmetlerimiz Kullanıcı İçeriği oluşturmanıza izin verebilir. Tarafınızca oluşturulan herhangi bir Kullanıcı İçeriğinin herhangi bir Fikri Mülkiyet hakkına yol açması halinde, bunların mülkiyeti sizde kalacak ve Kullanıcı İçeriğinizi herhangi bir şekilde ve herhangi bir amaç için size tazminat ödemeden kullanmamız, bunlardan yararlanmamız veya değiştirmemiz için bize dünya çapında, kalıcı, geri alınamaz, telifsiz, ücretsiz, tamamen devredilebilir bir lisans vermiş olursunuz. Ayrıca, Kullanıcı İçeriğinizle ilgili olarak şu anda veya gelecekte sahip olabileceğiniz manevi hakları uygulamamayı ve yasal olarak mümkün olan en geniş ölçüde feragat etmeyi kabul etmiş olursunuz.
                  </li>
                </ul>

              </p>

              <br/>

              <h2>Hesaplar ve Giriş</h2>

              <p>

                <ul>
                  <li>
                    Hizmetlerimizin belirli bölümleri bir Hesap oluşturmanıza veya Hizmetlere başka şekilde giriş yapmanıza olanak sağlayabilir. Bunu yaptığınızda, oturum açma bilgilerinizi korumak ve gizli tutmak için makul adımları atmanız gerekir. Erişime sizin tarafınızdan yetki verilmiş olsun ya da olmasın, Hesabınızı kullanarak gerçekleştirilen tüm faaliyetlerden siz sorumlusunuz. Hesabınızı kullanan herkesin bunu yapmak için izninize sahip olduğunu varsayacağız.
                  </li>
                </ul>

              </p>

              <br/>

              <h2>Güncellemeler ve Hizmetlerimize Erişim</h2>

              <p>

                <ul>
                  <li>
                    Hizmetlerimizi (tamamen veya kısmen) herhangi bir zamanda herhangi bir nedenle geri çekme veya değiştirme hakkına sahibiz. Şüpheye mahal vermemek adına, tamamen kendi takdirimize bağlı olarak Hizmetlerimizi değiştirme hakkımızın olduğunu anlıyorsunuz.
                  </li>
                  <li>
                    Hizmetlerimizin veya Hizmetlerimizin herhangi bir bölümünün teknik veya bakımla ilgili nedenlerden dolayı, planlı veya plansız olarak kullanılamadığı zamanların olabileceğini anlıyorsunuz.
                  </li>
                  <li>
                    Tamamen kendi takdirimize bağlı olarak Güncellemeler sağlayabiliriz ve Hizmetlerimizdeki Güncellemeleri kabul etmenizi isteyebiliriz.
                  </li>
                  <li>
                    Hizmetlerimizi alabilmek için zaman zaman üçüncü taraf yazılımlarını güncellemeniz gerekebilir.
                  </li>
                  <li>
                    Güncellemeler mevcut olduğunda Hizmetlerimizi ve üçüncü taraf yazılımlarımızı güncellemek sizin sorumluluğunuzdadır. Güncelleme yapmamanız sonucunda uğrayabileceğiniz herhangi bir Kayıptan sorumlu değiliz.
                  </li>
                  <li>
                    Hizmetlerimizi kullanımınızla ilgili olarak tavsiye, eğitim, hata düzeltme, değişiklikler, yeni sürümler, geliştirmeler, barındırma, telekomünikasyon, internet veya diğer hizmetleri sağlayarak Hizmetimizi desteklemek zorunda değiliz.
                  </li>
                </ul>

              </p>

              <br/>

              <h2>Üçüncü Taraf Hizmetleri</h2>

              <br/>

              <p>
                <ul>
                  <li>
                    Hizmetlerimizi satın aldığınızda, yüklediğinizde veya güncellediğinizde Google Play Store dahil ancak bununla sınırlı olmamak üzere üçüncü taraf hizmetler kullanılabilir.
                  </li>
                  <li>
                    Hizmetlerimizi kullandığınızda, oyun kaydetme ve paylaşma, sosyal medya bağlantısı ve benzeri dahil ancak bunlarla sınırlı olmamak üzere üçüncü taraf hizmetleri kullanılabilir.
                  </li>
                  <li>
                    Bu üçüncü taraf hizmetleri, ilgili üçüncü taraf hüküm ve koşullarına tabidir. Sizinle ilgili üçüncü taraf hizmet sağlayıcısı arasında bir anlaşma teşkil ettiğinden, lütfen bu üçüncü taraf hüküm ve koşullarını dikkatle inceleyin. Bu tür üçüncü tarafların faaliyetlerinden sorumlu değiliz.
                  </li>
                  <li>
                    Hizmetlerimiz, üçüncü taraf web sitelerine veya diğer üçüncü taraf içeriğine veya hizmetlerine (reklamlar aracılığıyla) bağlantılar içerebilir. Bu bağlantılar yalnızca kolaylık sağlamak amacıyla sağlanmıştır ve güncel kalamaz veya sürdürülemez. Üçüncü taraf içeriğine veya hizmetlerine olan bağlantıların, üçüncü tarafların veya onlar tarafından sağlanan herhangi bir içerik veya hizmetin tarafımızdan tasvip veya tavsiye edilmediğini, onaylanmadığını anlıyorsunuz. Herhangi bir üçüncü taraf içeriğini veya hizmetini kullanımınızın ayrı şart ve koşullara tabi olabileceğini anlıyorsunuz. Üçüncü taraflara veri sağladığınızda, bunu onların gizlilik politikalarına (varsa) uygun olarak sağladığınızı ve Gizlilik Politikamızın bu veriler için geçerli olmadığını anlıyorsunuz.
                  </li>
                </ul>
              </p>

              <h2>Reklamlar</h2>

              <br/>

              <p>
                <ul>
                  <li>
                    Hizmetlerimizin bizden ve/veya üçüncü taraflardan gelen reklamları içerebileceğini anlıyorsunuz.
                  </li>
                  <li>
                    Hizmetin reklam içermeyen bir Hizmet olacağını açıkça belirtmediğimiz sürece, tamamen kendi takdirimize bağlı olarak, şu anda reklam içermeyen herhangi bir Hizmetimize reklam koyabileceğimizi anlıyorsunuz.
                  </li>
                  <li>
                    Herhangi bir üçüncü taraf reklamının içeriğinden veya üçüncü taraf reklamlarıyla bağlantı verilen herhangi bir web sitesinin veya diğer materyallerin içeriğinden sorumlu olmadığımızı anlıyorsunuz ve bu üçüncü taraf web sitelerini ve materyallerini tüm risk size ait olmak üzere görüntülüyorsunuz.
                  </li>
                  <li>
                    Üçüncü taraf reklamverenlere Gizlilik Politikamıza uygun olarak bilgi sağladığımızı anlıyorsunuz.
                  </li>
                </ul>
              </p>

              <br/>

              <h2>Hesap ve Servislerin Sonlandırılması</h2>

              <p>
                <ul>
                  <li>
                    Bu Koşullardan herhangi birine uymamanız veya uymadığınızdan şüphelenmemiz durumunda, veya Gizlilik Politikamız veya Hizmetlerimizin herhangi bir fiili veya şüphelenilen yasa dışı veya uygunsuz kullanımı nedeniyle, Size bildirimde bulunarak veya bulunmadan Hesabınızı veya Hizmetlerimize veya Hizmetlerimizin bazı bölümlerine (Kullanıcı İçeriği dahil) erişiminizi sınırlama, askıya alma, sonlandırma, değiştirme veya silme hakkını saklı tutarız.
                  </li>
                  <li>
                    Hesabınızı hareketsizlik dışında feshedersek, başka hiçbir Hesaba erişmemeli veya başka Hesap oluşturmamalısınız.
                  </li>
                  <li>
                    180 gün veya daha uzun bir süre boyunca Hesapla ilgili olarak tarafınızca herhangi bir faaliyet gerçekleştirilmemesi durumunda Hesabınızı silme hakkımız saklıdır. Hesabınızın bu nedenle silinmesi durumunda, söz konusu Hesapla ilişkili Sanal Öğelere artık erişemeyecek ve/veya bunları kullanamayacaksınız ve size herhangi bir geri ödeme yapılmayacaktır.
                  </li>
                  <li>
                    Hesabınızı silerseniz veya Hesabınızı bu Koşullara uygun olarak silersek, daha önce Hesabınızla ilişkilendirilen verilere (Hizmetlerimizde ulaştığınız seviye veya puan ve Hesabınızla ilişkili tüm Sanal Öğeler dahil ancak bunlarla sınırlı olmamak üzere) erişiminizi kaybedebileceğinizi anlıyorsunuz.
                  </li>
                  <li>
                    Feshin ardından, Hizmetlerimizin kalan kopyalarını ve ilgili tüm belgeleri imha etmeniz veya bu tür materyalleri bizim yönlendirdiğimiz şekilde iade etmeniz veya imha etmeniz gerekir.
                  </li>
                  <li>
                    Bu Koşulların sizin tarafınızdan herhangi bir şekilde ihlal edilmesinden kaynaklanabilecek tüm kayıplar, zararlar, iddialar ve masraflar için yasalara uygun olarak bize tazminat ödemeyi kabul edersiniz.
                  </li>
                </ul>
              </p>

              <br/>

              <h2>Yasal Uyarı ve İbra</h2>

              <p>

                YASALARIN İZİN VERDİĞİ AZAMİ ÖLÇÜDE:

                <ul>
                  <li>
                    HİZMETLERİMİZİN VEYA HERHANGİ BİR KISMININ SAĞLANMAMASI;
                  </li>
                  <li>
                    HİZMETLERİMİZİN KULLANIMI ESNASINDA VEYA HİZMETLERİMİZİN PARÇASI OLARAK ORTAYA ÇIKAN VERİ KAYBI VEYA HATALAR VEYA KESİNTİLER;
                  </li>
                  <li>
                    HİZMETLERİMİZİN VEYA HERHANGİ BİR KISMININ ASKIYA ALINMASI VEYA DURDURULMASI;
                  </li>
                  <li>
                    HİZMETLERİMİZİN DİĞER KULLANICILAR TARAFINDAN BU ŞARTLARI İHLAL EDEN HERHANGİ BİR KULLANIM DAHİL OLMASI DAHİL, HİZMETLERİMİZİN DİĞER KULLANICILAR TARAFINDAN HER TÜRLÜ KULLANIMI;
                  </li>
                </ul>

                DOLAYISIYLA DOĞRUDAN VEYA DOLAYLI OLARAK HERHANGİ BİR İDDİA, KAYIP YA DA KAYIPLARDAN SORUMLU DEĞİLİZ.

              </p>

              <br/>

              <h2>Garanti</h2>

              <p>
                <ul>
                  <li>
                    HİZMETLERİMİZ, KULLANIMINIZ İÇİN “OLDUĞU GİBİ” VE “MEVCUT OLDUĞU ŞEKİLDE” SUNULMAKTADIR. HİZMETLERİMİZİN HATASIZ OLMASININ GARANTİ EDİLMEDİĞİNİ VE HERHANGİ BİR HATANIN MEVCUT OLMASININ BU KOŞULLARIN İHLALİ OLMAYACAĞINI ANLIYORSUNUZ.
                  </li>
                  <li>
                    BU ŞARTLARDA AKSİ AÇIKÇA BELİRTİLENLER HARİCİNDE VE YASALARIN İZİN VERDİĞİ ÖLÇÜDE:
                    <ul>
                      <li>
                        BU ŞARTLARIN İHLALİNDEN VEYA KUSURLU HİZMETLERİN TEDARİĞİNDEN KAYNAKLANAN ÖZEL, DOLAYLI VEYA SONUÇ OLARAK ORTAYA ÇIKAN ZARARLAR (KÂR KAYBI GİBİ) VEYA İDDİALAR DAHİL OLMAK ÜZERE HERHANGİ BİR KAYIPTAN SİZE KARŞI YÜKÜMLÜ OLMAYACAĞIZ;
                      </li>
                    </ul>
                  </li>
                  <li>
                    HİZMETLERİMİZİ ALIRKEN BAĞIMSIZ KARARINIZI KULLANDIĞINIZI VE BU ŞARTLARDA AÇIKÇA BELİRTİLMEYEN VEYA TARAFIMIZDAN ÜRETİLEN KATALOGLAR VEYA TANITIM MATERYALLERİ DAHİL HERHANGİ BİR BELGEDE YER ALAN AÇIKLAMALAR VEYA ÇİZİMLER VEYA ÖZELLİKLER ÜZERİNDE HİÇBİR BEYANIMIZA İTİMAT ETMEDİĞİNİZİ KABUL EDİYORSUNUZ.
                  </li>
                </ul>
              </p>

              <br/>

              <h2>Güvenlik</h2>

              <p>
                <ul>
                  <li>
                    Maalesef internet üzerinden hiçbir veri aktarımının tamamen güvenli olduğu garanti edilemez. Bu tür bilgileri korumaya çalışsak da, bize ilettiğiniz bilgilerin güvenliğini garanti edemeyiz. Buna göre bize ilettiğiniz her türlü bilginin iletilmesi riski size ait olmak üzere iletilir. Ancak iletinizi aldıktan sonra güvenliğini korumak için makul adımları atacağız.
                  </li>
                  <li>
                    Hizmetlerimize erişiminizin sizi virüs, kötü amaçlı bilgisayar kodu veya Cihazlarınıza zarar verebilecek diğer müdahale biçimleri riskine maruz bırakmamasını sağlamak için kendi önlemlerinizi almalısınız. Hizmetlerimizi kullanmanızla bağlantılı olarak Cihazlarınızda ortaya çıkan herhangi bir müdahale veya hasarın sorumluluğunu kabul etmiyoruz.
                  </li>
                </ul>
              </p>

              <br/>

              <h2>Gizlilik</h2>

              <p>
                <ul>
                  <li>
                    Kişisel bilgilerinizi Gizlilik Bildirimimize uygun olarak, bu Koşullarda belirtildiği şekilde ve sizin onayınız doğrultusunda toplayacak, işleyecek, kullanacak ve paylaşacağız. Gizlilik Bildirimimiz bu Koşulların bir parçasını oluşturur.
                  </li>
                  <li>
                    Hizmetlerimizi kullanarak Gizlilik Bildirimimiz de dahil olmak üzere bu Koşulları kabul etmiş ve sözleşmenizin Hizmetleri kullanımınızın bir ön koşulu olduğunu kabul etmiş olursunuz.
                  </li>
                  <li>
                    Topladığımız her türlü kişisel bilgi, Hizmetlerimize bağlantı vermeyi kabul ettiğiniz herhangi bir sosyal ağın politikasına da tabi olabilir. Hizmetlerimizi kullanırsanız ve onların bir sosyal ağ ile etkileşime girmesine izin vermeyi kabul ederseniz, söz konusu sosyal ağın bizimle paylaştığı ve sizi kişisel olarak tanımlamamıza olanak sağlayacak her türlü bilgiyi kullanmamıza izin vermiş olursunuz. Ayrıca, sosyal olarak oynayabilmeniz için o ağdaki bağlantılarınızın sizi bulmasına yardımcı olmamıza da izin veriyorsunuz. Bunun amacı, Hizmetlerimizi siz ve söz konusu sosyal ağda Hizmetlerimizi kullanan diğer kişiler için daha keyifli hale getirmektir. Bu uygulamaları kabul etmiyorsanız Hizmetlerimizin sosyal ağınızla etkileşime girmesine izin vermemelisiniz.
                  </li>
                  <li>
                    Hizmetlerimizi her kullandığınızda belirli türdeki bilgileri depolamak için çerezler veya benzer teknolojiler kullanabiliriz. Örneğin, cihazınızı tanımamıza yardımcı olmak ve Hesabınıza, o Hesap için doğru kullanıcı adını ve şifreyi giren kişi tarafından erişilmesini sağlamak için kullanılabilirler. Gizlilik Bildirimimizi okuyarak çerezleri ve diğer benzer teknolojileri nasıl kullandığımız hakkında daha fazla bilgi edinebilirsiniz.
                  </li>
                </ul>
              </p>


              <br/>

              <h2>Genel</h2>

              <p>
                <ul>
                  <li>
                    <b>Devir</b> - Bu Şartlar kapsamındaki haklarımızın veya sorumluluklarımızın tamamını veya bir kısmını izninizi almadan başka birine devretmek isteyebiliriz. Transferin size önemli bir dezavantaj yaratmaması koşuluyla bunu yapabileceğimizi kabul edersiniz. Bu Koşullar kapsamında size verdiğimiz hakların hiçbirini, önceden yazılı olarak kabul etmediğimiz sürece devredemezsiniz.
                  </li>
                  <li>
                    <b>Sözleşmenin tamamı</b> - Bu Koşullar, Gizlilik Politikamız, Hizmetlerimizle ilgili olarak sizinle aramızdaki sözleşmenin tamamını belirler ve sizinle aramızdaki önceki tüm sözleşmelerin ve mutabakatların yerine geçer.
                  </li>
                  <li>
                    <b>Bölünebilirlik</b> - Bu Koşulların herhangi bir bölümünün geçersiz veya uygulanamaz olduğu tespit edilirse, bu Koşulların tam olarak yürürlükte, etkili ve uygulanabilir kalması için söz konusu bölüm gereken minimum ölçüde sınırlandırılacak veya ortadan kaldırılacaktır.
                  </li>
                  <li>
                    <b>Feragat</b> - Bu Şartlar kapsamındaki haklarımızdan herhangi birini kullanmamamız veya uygulamamamız, bu hakkı uygulama hakkımızdan feragat anlamına gelmez. Bu tür haklardan herhangi bir feragat, yalnızca yazılı olması ve tarafımızdan imzalanması durumunda geçerli olacaktır.
                  </li>
                  <li>
                    <b>Mücbir Sebep</b> - Bu Koşullarda yer alan diğer hükümlere bakılmaksızın, gecikme veya başarısızlığın, yangın, sel, doğal afet, grev, lokavt, işin durması, ticari anlaşmazlıklar veya herhangi bir savaş veya terör eylemi gibi kontrolümüz dışındaki durumlardan kaynaklanması durumunda, bu Koşullarda herhangi bir gecikme veya bu Koşullara uyulmamasından sorumlu olmayacağız.
                  </li>
                </ul>
              </p>

              <br/>

              <h2>İletişim</h2>

              <p>
                Bu Koşullar veya Hizmetlerimiz hakkında herhangi bir sorunuz varsa bizimle şu adresten iletişime geçebilirsiniz:
              </p>
              <p>
                <b>
                  support@kastrakomnen.com
                </b>
              </p>


              <br/>

              <h2>Tanımlar</h2>

              <p>
                Bu Koşullarda, bağlam aksini gerektirmedikçe, aşağıdaki kelimeler aşağıdaki anlamlara sahiptir:

                <ul>
                  <li>
                    <b>Hesap</b>, kayıtlı bir kullanıcı hesabı anlamına gelir.
                  </li>
                  <li>
                    <b>İddia</b>, şu anda mevcut olan veya gelecekte herhangi bir zamanda ortaya çıkan ve halihazırda meydana gelmiş veya gelecekte meydana gelebilecek olay veya koşullara atfedilebilen herhangi bir dava, iddia, dava veya talep anlamına gelir.
                  </li>
                  <li>
                    <b>Fikri Mülkiyet</b>, patentler, telif hakları (manevi haklar dahil), tasarımlar, tescilli ve tescilsiz ticari markalar, ticari sırlar, teknik bilgi ve gizli bilgiler ve diğer tüm fikri mülkiyet hakları ve bu hakları tescil ettirme hakkına ilişkin dünya çapındaki tüm hakları içerir. Bu belgenin tarihinden önce veya sonra oluşturulmuş ve her durumda bu hakların süresi ve herhangi bir yenilenme süresi boyunca.
                  </li>
                  <li>
                    <b>Kayıp</b>, doğrudan veya dolaylı veya sonuç olarak veya başka bir şekilde maruz kalınan veya maruz kalınan tüm kayıplar, maliyetler, giderler ve zararlar (yasal maliyetler ve harcamalar dahil) anlamına gelir.
                  </li>
                  <li>
                    <b>Sağlayıcı Kredileri</b>, üçüncü taraf bir sağlayıcıda sizin tarafınızdan tutulan para birimi benzeri krediler anlamına gelir.
                  </li>
                  <li>
                    <b>Gizlilik Politikası</b>, zaman zaman güncellenen ve Gizlilik Politikası'ndan erişilebilen gizlilik politikamız anlamına gelir.
                  </li>
                  <li>
                    <b>Hizmetler</b>, tarafımızdan sunulan hizmetler anlamına gelir ve oyunlarımızı, web sitelerimizi ve ilgili hizmetlerimizi içerir.
                  </li>
                  <li>
                    <b>Şartlar</b>, bu belgede belirtilen ve zaman zaman güncellenen şartlar anlamına gelir.
                  </li>
                  <li>
                    <b>Güncelleme</b>, orijinal Hizmetin yerine geçen veya onu tamamlayan, tarafımızdan sağlanan bir güncelleme anlamına gelir ve tamamen kendi takdirimize bağlı olarak Hizmetlerimizin özelliklerinin eklenmesini, kaldırılmasını, değiştirilmesini veya başka şekilde değiştirilmesini içerebilir.
                  </li>
                  <li>
                    <b>Kullanıcı İçeriği</b>, Hizmetlerimizi kullanmanın bir sonucu olarak oluşturulan, oluşturulan, yapılan veya başka şekilde ortaya çıkan her türlü içerik anlamına gelir. Kullanıcı etkileşimi bağlamında buna, herhangi bir sohbet metni de dahil olmak üzere, Hizmetlerimiz aracılığıyla yüklediğiniz veya ilettiğiniz ya da diğer kullanıcıların yüklediği veya ilettiği her türlü iletişim, resim, ses ve tüm materyal, veri ve bilgiler dahildir.
                  </li>
                  <li>
                    <b>Sanal Öğeler</b>, Hizmetlerimiz kapsamında kullanıma yönelik her türlü sanal öğe veya nesne veya diğer yetkiler anlamına gelir ve her türlü sanal para birimini içerir.
                  </li>
                </ul>

              </p>

              <br/>
              <br/>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default PrivacyPolicyTr;

