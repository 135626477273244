import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css'; // Import the CSS file for styling
import jwt from 'jwt-decode'
import {EndpointEntries, EndpointStats} from "./Endpoints";
import {FaBars, FaCross, FaWindowClose} from 'react-icons/fa'; // Import the burger icon from a react-icons library
import { FaSignOutAlt } from 'react-icons/fa';


const HomePage = () => {
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [entries, setEntries] = useState(0);
  const date = new Date(); // Replace with the date you want to display the month for
  const monthName = new Intl.DateTimeFormat('tr', { month: 'long' }).format(date);


  const [idToken, setIdToken] = useState(null);
  const [workerId, setWorkerId] = useState(null);

  const [wordId, setWordId] = useState(null);
  const [word, setWord] = useState(null);
  const [meaning, setMeaning] = useState(null);

  const [t1, setT1] = useState("");
  const [t2, setT2] = useState("");
  const [t3, setT3] = useState("");
  const [t4, setT4] = useState("");
  const [t5, setT5] = useState("");

  const [filter, setFilter] = useState();

  const notificationBiasType = {'notr':'notr', 'good':'good', 'bad':'bad'}
  const [showNotification, setShowNotification] = useState(true);
  const [notificationBias, setNotificationBias] = useState(notificationBiasType.notr);
  const [notification, setNotification] = useState("Bildirimleri Burada Görebilirsiniz");

  const NotificationEntryAccepted = "Giriş Kabul Edildi"
  const NotificationEntryRejected = "Giriş Reddedildi"

  const NotificationTabooWordsFormatError = "Tabu Kelime/ler Formatları Yanlış"
  const NotificationTabooWordsEmpty = "Tabu Kelime/ler Boş Kaldı"
  const NotificationTabooWordsAreSame = "Tabu Kelimeler Aynı"
  const NotificationTabooWordsNotFoundInDictionary = "Tabu Kelime/ler Sözlükte Bulunamadı"
  const NotificationNoWord = "Kelime Yok"
  const NotificationNoUser = "Kullanıcı Yok"
  const NotificationSomethingGoesWrong = "Birşeyler Ters Gitti"

  const FilterTypes = {
    'Appropriate': 0,
    'HateSpeech': 1,
    'Violence': 2,
    'AdultContent': 3,
    'CopyrightViolation': 4,
    'ReligiousContent': 5,
    'SpellingError': 6
  }

  useEffect(() => {
    const storedIdToken = localStorage.getItem('idToken');
    setIdToken(storedIdToken);

    if (storedIdToken) {

      try {
        const decoded = jwt(storedIdToken);
        console.log(`id token ${idToken}`)
        console.log(`sub field ${decoded.sub}`)
        setWorkerId(decoded.sub)
        console.log(`worker id ${workerId}`)
        getWord();
        getStats()
        setFilter(FilterTypes.Appropriate)
      } catch (e) {
        console.log(e)
      }

    }
  }, []);

  const getWord = () => {
    // Send a GET request to the endpoint with Authorization header
    if (idToken) {
      fetch(EndpointEntries, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {

          if (data.body.length >= 1) {
            console.log(data.body)
            setWord(data.body[0].word)
            setMeaning(data.body[0].meaning)
            setWordId(data.body[0].id)
            console.log(`wordId ${data.body[0].id}`)
          } else {
            setWord("----")
            setWordId(null)
          }

          // Handle the response data as needed
        })
        .catch((error) => {
          console.error('Request error:', error);
          // Handle the error
        });
    }
  }

  const postWord = () => {
    return new Promise((resolve, reject) => {

      if (wordId == null) {
        reject(NotificationNoWord)
      }

      if (word == null) {
        reject(NotificationNoWord)
      }

      if (t1.length === 0 || t2.length === 0 || t3.length === 0 || t4.length === 0 || t5.length === 0) {
        reject(NotificationTabooWordsEmpty)
      }

      const regex = /^(?:[a-zçğıöşü]+\s)*[a-zçğıöşü]+$/;

      const t1_lower = t1.toLowerCase();
      const t2_lower = t2.toLowerCase();
      const t3_lower = t3.toLowerCase();
      const t4_lower = t4.toLowerCase();
      const t5_lower = t5.toLowerCase();

      if (!regex.test(t1_lower)) reject(`${NotificationTabooWordsFormatError}. \`${t1_lower}\``)
      if (!regex.test(t2_lower)) reject(`${NotificationTabooWordsFormatError}. \`${t2_lower}\``)
      if (!regex.test(t3_lower)) reject(`${NotificationTabooWordsFormatError}. \`${t3_lower}\``)
      if (!regex.test(t4_lower)) reject(`${NotificationTabooWordsFormatError}. \`${t4_lower}\``)
      if (!regex.test(t5_lower)) reject(`${NotificationTabooWordsFormatError}. \`${t5_lower}\``)

      if (workerId == null) {
        reject(NotificationNoUser)
      }

      const requestBodyData = {
        "wordId": wordId,
        "word": word,
        "t1": t1_lower,
        "t2": t2_lower,
        "t3": t3_lower,
        "t4": t4_lower,
        "t5": t5_lower,
        "workerId": workerId,
        "filter": filter
      };

      if (idToken) {

        fetch(EndpointEntries, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${idToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestBodyData)
        })
          .then((response) => response.json())
          .then((data) => {



            if (data.statusCode === 200) {
              resolve(NotificationEntryAccepted)
            } else if (data.statusCode === 400) {
              if (data.message === "NotFoundInDictionary") {
                reject(`${NotificationEntryRejected} ${NotificationTabooWordsNotFoundInDictionary} \`${data.details}\``)
              } else if (data.message === "TabooWordsAreSame") {
                reject(`${NotificationEntryRejected} ${NotificationTabooWordsAreSame}`)
              }
            } else {
              reject(`${NotificationEntryRejected} ${NotificationSomethingGoesWrong}`)
            }

          })
          .catch((error) => {
            reject(`${NotificationEntryRejected} ${NotificationSomethingGoesWrong}`)
          });
      }
    })
  }

  const handleHideNotification = () => {
    setNotificationBias(notificationBiasType.notr)
    setShowNotification(false);
  };

  const getStats = () => {

    const currentDate = new Date();
    // Get the month as a number (0 for January, 1 for February, etc.)
    const currentMonth = currentDate.getMonth() + 1;

    fetch(`${EndpointStats}/${workerId}/${currentMonth}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((data) => {

        setEntries(data.entries)
        console.log('Response data:', data);

      })
      .catch((error) => {

        console.error('Request error:', error);

      });
  }

  return (
    <div className="home-page">
      <header className="header">
        {/*<div className="burger-icon" onClick={() => setIsMenuOpen(!isMenuOpen)}>*/}
        {/*  <FaBars />*/}
        {/*</div>*/}
        <h2 className="title">wetaboo Kelime Girişi</h2>
        <button className="logout-button-icon" onClick={() => {
          window.location.href = "https://mark-1.auth.eu-central-1.amazoncognito.com/logout?client_id=3eb2b8ij4e2ojr3jiui6es2ahq&logout_uri=https://www.gyropole.com";
        }}>
          <FaSignOutAlt /> {/* Add the icon element here */}
        </button>
      </header>

      {isMenuOpen && (
        <nav className="nav-menu">
          {/* Navigation links */}
        </nav>
      )}

      <div className="home-page">
        <div className="stat-box">
          <button className="refresh-button" onClick={getStats}> İstatistik Güncelleme </button>

          <h3>{monthName} Ayında Onaylanan Kelime Sayısı {entries}</h3>

          <button className="refresh-button" onClick={getWord}> Kelimeyi Yenile </button>
        </div>

        <div>
          {showNotification && (
            <div className={`notification ${notificationBias.toLowerCase()}`}>
              <p>{notification}</p>
              <button className="close-button-icon" onClick={handleHideNotification}>
                <FaWindowClose/>
              </button>
            </div>
          )}
        </div>
        <hr />
        <p className="bigger-text">{word}</p>
        <p className="middle-text">{meaning}</p>
        <input className="input-box" placeholder="Tabu Kelime 1" value={t1} onChange={(event) => {setT1(event.target.value)}}/>
        <input className="input-box" placeholder="Tabu Kelime 2" value={t2} onChange={(event) => {setT2(event.target.value)}}/>
        <input className="input-box" placeholder="Tabu Kelime 3" value={t3} onChange={(event) => {setT3(event.target.value)}}/>
        <input className="input-box" placeholder="Tabu Kelime 4" value={t4} onChange={(event) => {setT4(event.target.value)}}/>
        <input className="input-box" placeholder="Tabu Kelime 5" value={t5} onChange={(event) => {setT5(event.target.value)}}/>

        <div>
          <select className="select-box" onChange={(event) => {

            console.log(event.target.value)

            if (event.target.value === String(FilterTypes.Appropriate)) {
              setFilter(FilterTypes.Appropriate)
            }else if (event.target.value === String(FilterTypes.HateSpeech)) {
              setFilter(FilterTypes.HateSpeech)
            }else if (event.target.value === String(FilterTypes.Violence)) {
              setFilter(FilterTypes.Violence)
            }else if (event.target.value === String(FilterTypes.AdultContent)) {
              setFilter(FilterTypes.AdultContent)
            }else if (event.target.value === String(FilterTypes.CopyrightViolation)) {
              setFilter(FilterTypes.CopyrightViolation)
            }else if (event.target.value === String(FilterTypes.ReligiousContent)) {
              setFilter(FilterTypes.ReligiousContent)
            }
          }} value={filter}>
            <option value={FilterTypes.Appropriate}         >Uygun Kelime</option>
            <option value={FilterTypes.HateSpeech}          >Nefret Söylemi</option>
            <option value={FilterTypes.Violence}            >Şiddet Barındırıyor</option>
            <option value={FilterTypes.AdultContent}        >Yetişkin İçerik</option>
            <option value={FilterTypes.CopyrightViolation}  >Telif Hakkı İhlali</option>
            <option value={FilterTypes.ReligiousContent}    >Dini İçerik</option>
            <option value={FilterTypes.SpellingError}       >Hatalı İmla</option>
          </select>
        </div>

        <hr />

        <button className="approve-button" onClick={() => {
          console.log(`t1 ${t1}`);
          console.log(`t2 ${t2}`);
          console.log(`t3 ${t3}`);
          console.log(`t4 ${t4}`);
          console.log(`t5 ${t5}`);
          postWord().then((message) => {
            setNotificationBias(notificationBiasType.good)
            setNotification(message)
            setShowNotification(true)
            setT1('')
            setT2('')
            setT3('')
            setT4('')
            setT5('')
            getWord()
            setFilter(FilterTypes.Appropriate)
          }).catch((e) => {
            setNotificationBias(notificationBiasType.bad)
            setNotification(e)
            setShowNotification(true)
          })

        }}>
          Onayla
        </button>
      </div>

    </div>
  );
};

export default HomePage;
