// Import React and CSS files
import React, {useEffect} from 'react';
import './page-layout.css';
import './menu.css';
import './document-layout.css';
import './document-text.css';
import './document-view-style.css';
import './built-in-view.css';
import './ide.css';
import './loader.css';

const DeleteAccount = () => {
  return (
    <div>
      HelloWorld!
    </div>
  );

};

export default DeleteAccount;
