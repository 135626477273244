function PrivacyPolicyTr() {
  return (
    <div>
      <div className="page myClass">
        <div className="translations-content-container">
          <div className="container">
            <div className="tab-content translations-content-item en visible" id="en">

              <br/>

              <h1>Gizlilik Politikası</h1>
              <p>Son Güncelleme: 30 Mart 2024</p>

              <br/>

              <p>Bu Gizlilik Politikası, Servisi kullanırken Bilgilerinizin toplanması, kullanılması ve açıklanması konusundaki politika ve prosedürlerimizi açıklar ve sizin gizlilik haklarınızı ve yasaların size nasıl koruma sağladığını anlatır.</p>
              <p>Servisi kullanarak, bu Gizlilik Politikası'na uygun olarak bilgilerin toplanmasını ve kullanılmasını kabul etmiş olursunuz. </p>

              <br/>
              <h2>Yorumlama ve Tanımlar</h2>

              <br/>
              <h3>Yorumlama</h3>

              <p>Büyük harfle başlayan kelimeler aşağıdaki koşullar altında tanımlanmıştır. Aşağıdaki tanımlar, tekil veya çoğul olmalarına bakılmaksızın aynı anlama gelir.</p>

              <br/>
              <h3>Tanımlar</h3>

              <p>Bu Gizlilik Politikası'nın amacı için aşağıdaki terimlerin anlamı şunlardır:</p>
              <ul>
                <li>
                  <p><strong>Hesap</strong>: Servisimize veya Servisimizin bir bölümüne erişim sağlamak için size özel oluşturulan bir hesaptır.</p>
                </li>
                <li>
                  <p><strong>İştirak</strong>: Bir tarafı kontrol eden, kontrol edilen veya birlikte kontrol edilen bir varlık anlamına gelir; "kontrol" ifadesi, yöneticilerin veya başka bir yönetim otoritesinin seçimine oy verme yetkisine sahip olan hisselerin, öz sermaye paylarının veya diğer oy kullanma hakkına sahip menkul kıymetlerin %50'sine veya daha fazlasına sahip olmayı ifade eder.</p>
                </li>
                <li>
                  <p><strong>Uygulama</strong>: Şirket tarafından sağlanan Wetaboo, Briket adlı yazılım programını ifade eder.</p>
                </li>
                <li>
                  <p><strong>Şirket</strong> (bu Anlaşma'da "Şirket", "Biz", "Bize" veya "Bizim" olarak anılacaktır) Gyropole şirketini ifade eder.</p>
                </li>
                <li>
                  <p><strong>Ülke</strong>: Türkiye'yi ifade eder.</p>
                </li>
                <li>
                  <p><strong>Cihaz</strong>: Servise erişebilen herhangi bir cihazı ifade eder, örneğin bir bilgisayar, cep telefonu veya dijital tablet.</p>
                </li>
                <li>
                  <p><strong>Kişisel Veri</strong>: Belirlenmiş veya belirlenebilir bir bireye ilişkin her türlü bilgiyi ifade eder.</p>
                </li>
                <li>
                  <p><strong>Servis</strong>: Uygulamayı ifade eder.</p>
                </li>
                <li>
                  <p><strong>Servis Sağlayıcı</strong>: Şirket adına veriyi işleyen herhangi bir gerçek veya tüzel kişiyi ifade eder. Bu, Servisi kolaylaştırmak, Şirket adına Servisi sağlamak, Servis ile ilgili hizmetleri gerçekleştirmek veya Şirketin Servisin nasıl kullanıldığını analiz etmesine yardımcı olmak amacıyla Şirket tarafından istihdam edilen üçüncü taraf şirketleri veya bireyleri içerir.</p>
                </li>
                <li>
                  <p><strong>Üçüncü Taraf Sosyal Medya Servisi</strong>: Bir Kullanıcının Servisi kullanmak için oturum açabileceği veya hesap oluşturabileceği herhangi bir web sitesini veya sosyal ağ web sitesini ifade eder.</p>
                </li>
                <li>
                  <p><strong>Kullanım Verisi</strong>: Otomatik olarak toplanan, Servisin kullanımı veya Servi'in altyapısı tarafından üretilen veriyi ifade eder (örneğin, bir sayfa ziyaretinin süresi).</p>
                </li>
                <li>
                  <p><strong>Siz</strong>: Hizmeti kullanmakta olan veya kullanmakta olan bireyi veya gerektiğinde hizmeti kullanmakta olan birey adına kullanılan şirketi veya diğer yasal varlığı ifade eder.</p>
                </li>
              </ul>

              <br/>
              <h2>Kişisel Verilerinizin Toplanması ve Kullanılması</h2>

              <br/>
              <h3>Toplanan Veri Türleri</h3>

              <br/>
              <h4>Kişisel Veri</h4>

              <p>Servisimizi kullanırken sizden iletişim kurmak veya sizi tanımlamak için kullanılabilecek belirli kişisel kimlik bilgilerinizi sağlamanızı isteyebiliriz. Kişisel kimlik bilgileri şunları içerebilir, ancak bunlarla sınırlı değildir:</p>
              <ul>
                <li>
                  <p>E-posta adresi</p>
                </li>
                <li>
                  <p>Ad ve soyad</p>
                </li>
                <li>
                  <p>Telefon numarası</p>
                </li>
                <li>
                  <p>Kullanım Verisi</p>
                </li>
              </ul>

              <br/>
              <h4>Kullanım Verisi</h4>

              <p>Servisi kullanırken Kullanım Verisi otomatik olarak toplanır.</p>
              <p>Kullanım Verisi, Cihazınızın İnternet Protokol adresini (örneğin, IP adresi), tarayıcı türünü, tarayıcı sürümünü, ziyaret ettiğiniz Servimizin sayfalarını, ziyaretinizin tarih ve saatini, bu sayfalarda geçirilen süreyi, benzersiz cihaz tanımlayıcılarını ve diğer tanımlama verilerini içerebilir.</p>
              <p>Servise bir mobil cihaz üzerinden eriştiğinizde, otomatik olarak belirli bilgileri toplayabiliriz, bunlar arasında kullandığınız mobil cihazın türü, mobil cihazınızın benzersiz kimliği, mobil cihazınızın IP adresi, mobil cihazınızın işletim sistemi, kullandığınız mobil İnternet tarayıcısının türü, benzersiz cihaz tanımlayıcıları ve diğer tanımlama verileri bulunabilir.</p>
              <p>Ayrıca, Servisimize bir bilgisayar üzerinden eriştiğinizde veya Servise bir mobil cihaz üzerinden eriştiğinizde tarayıcınızın gönderdiği bilgileri de toplayabiliriz.</p>

              <br/>
              <h4>Üçüncü Taraf Sosyal Medya Servisinden Bilgi</h4>

              <p>Şirket, Servisi kullanmak için hesap oluşturmanıza ve oturum açmanıza izin verir ve aşağıdaki Üçüncü Taraf Sosyal Medya Servisleri aracılığıyla:</p>
              <ul>
                <li>Google</li>
                <li>Facebook</li>
              </ul>
              <p>Eğer bize üçüncü taraf sosyal medya hizmeti üzerinden kaydolmaya veya başka bir şekilde erişim izni vermeye karar verirseniz, Üçüncü Taraf Sosyal Medya Hizmeti'nin hesabınızla zaten ilişkilendirilmiş olan Kişisel verileri toplayabiliriz. Bu veriler arasında adınız, e-posta adresiniz, faaliyetleriniz veya o hesapla ilişkilendirilmiş iletişim listesi gibi bilgiler bulunabilir.</p>
              <p>Ayrıca, Şirket ile Üçüncü Taraf Sosyal Medya Hizmeti'nin hesabınız aracılığıyla ek bilgileri paylaşma seçeneğiniz olabilir. Bu tür bilgileri ve Kişisel Verileri sağlamayı seçerseniz, kayıt sırasında veya başka bir şekilde, bu Gizlilik Politikası ile uyumlu bir şekilde kullanma, paylaşma ve depolama izni vermiş olursunuz.</p>

              <br/>
              <h3>Kişisel Verilerinizin Kullanımı</h3>

              <p>Şirket, Kişisel Verileri aşağıdaki amaçlar için kullanabilir:</p>
              <ul>
                <li>
                  <p><strong>Hizmetimizi sağlamak ve sürdürmek için:</strong> Hizmetimizin kullanımını izlemek dahil olmak üzere.</p>
                </li>
                <li>
                  <p><strong>Hesabınızı yönetmek için:</strong> Hizmet kullanıcısı olarak kaydınızı yönetmek. Sağladığınız Kişisel Veriler, size kayıtlı bir kullanıcı olarak sunulan Hizmet'in farklı işlevlerine erişim sağlayabilir.</p>
                </li>
                <li>
                  <p><strong>Bir sözleşmenin yerine getirilmesi için:</strong> Satın aldığınız ürünler, öğeler veya Hizmet üzerinden bize karşı gerçekleştirdiğiniz diğer herhangi bir sözleşmenin geliştirilmesi, uygunluğu ve yerine getirilmesi.</p>
                </li>
                <li>
                  <p><strong>Sizinle iletişim kurmak için:</strong> Size e-posta, telefon aramaları, SMS veya diğer eşdeğer elektronik iletişim biçimleri aracılığıyla ulaşmak. İhtiyaç duyulması veya uygulanması gerektiğinde güncellemeler veya bilgilendirici iletişimlerle ilgili mobil uygulama bildirimleri de dahil.</p>
                </li>
                <li>
                  <p><strong>Size sunmak için:</strong> Size daha önce satın aldığınız veya soruşturduğunuz ürünler, hizmetler ve etkinliklerle ilgili haberler, özel teklifler ve genel bilgiler.</p>
                </li>
                <li>
                  <p><strong>Taleplerinizi yönetmek için:</strong> Bize olan taleplerinizi karşılamak ve yönetmek.</p>
                </li>
                <li>
                  <p><strong>İş devirleri için:</strong> Bilgilerinizi, Servis kullanıcıları hakkında bize ait olan varlıkların birleşme, devir, yeniden yapılanma, reorganizasyon, tasfiye veya benzer bir süreç kapsamında bir şirkete satışı veya transferi ile değerlendirebilir veya kullanabiliriz.</p>
                </li>
                <li>
                  <p><strong>Diğer amaçlar için</strong>: Bilgilerinizi, veri analizi, kullanım trendlerini belirleme, promosyon kampanyalarımızın etkinliğini değerlendirme ve Hizmetimizi, ürünlerimizi, hizmetlerimizi, pazarlamamızı ve deneyiminizi değerlendirme ve iyileştirme gibi diğer amaçlar için kullanabiliriz.</p>
                </li>
              </ul>
              <p>Şahsi bilgilerinizi aşağıdaki durumlarda paylaşabiliriz:</p>
              <ul>
                <li><strong>Hizmet Sağlayıcılar ile:</strong> Hizmetimizin kullanımını izlemek ve analiz etmek, sizi iletişim kurmak için Kişisel bilgilerinizi Hizmet Sağlayıcılar ile paylaşabiliriz.</li>
                <li><strong>İş devirleri için:</strong> Şirket varlıklarının bir kısmının veya tamamının başka bir şirkete birleşme, varlık satışı, finansman veya işimizin bir kısmının başka bir şirkete satın alınması ile ilgili müzakereler sırasında Kişisel bilgilerinizi paylaşabilir veya transfer edebiliriz.</li>
                <li><strong>Affiliate Şirketler ile:</strong> Kişisel bilgilerinizi Affiliate Şirketlerimizle paylaşabiliriz, bu durumda bu Affiliate Şirketlerin bu Gizlilik Politikasını onurlandırmalarını talep edeceğiz. Affiliate Şirketlerimiz, ana şirketimiz ve başka bir şirketle ortak kontrol altında olan veya bize ortak kontrol altında olan diğer şirketler içerebilir.</li>
                <li><strong>İş Ortağı ile:</strong> Kişisel bilgilerinizi İş Ortağımızla paylaşabiliriz, bu durumda size belirli ürünler, hizmetler veya promosyonlar sunmak için.</li>
                <li><strong>Diğer kullanıcılar ile:</strong> Diğer kullanıcılarla kişisel bilgi paylaşırsanız veya diğer kullanıcılarla genel alanlarda başka bir şekilde etkileşimde bulunursanız, bu bilgiler tüm kullanıcılar tarafından görüntülenebilir ve dışa açık bir şekilde dağıtılabilir. Üçüncü Taraf Sosyal Medya Hizmeti üzerinden kayıt yaptırırsanız, bu Hizmet üzerindeki kişileriniz adınızı, profilinizi, resimlerinizi ve faaliyet açıklamanızı görebilir. Benzer şekilde, diğer kullanıcılar etkinlik açıklamalarınızı görebilir, sizinle iletişim kurabilir ve profilinizi görebilir.</li>
                <li><strong>Onayınız ile:</strong> Onayınızla başka bir amaç için Kişisel bilgilerinizi açıklayabiliriz.</li>
              </ul>

              <br/>
              <h3>Kişisel Verilerinizin Saklanması</h3>

              <p>Şirket, Kişisel Verilerinizi bu Gizlilik Politikası'nda belirtilen amaçlar doğrultusunda sadece gerekli olduğu sürece saklayacaktır. Kişisel Verilerinizi kullanma ve saklama, yasal yükümlülüklerimize uymak (örneğin, uygulanabilir yasalara uymak için verilerinizi saklamamız gerekiyorsa), anlaşmazlıkları çözmek ve yasal anlaşma ve politikalarımızı uygulamak için gerektiği kadar kullanacağız.</p>
              <p>Şirket aynı zamanda İçsel analiz amaçları için Kullanım Verilerini saklayacaktır. Kullanım Verileri genellikle daha kısa bir süre için saklanır, ancak bu verilerin güvenliğini güçlendirmek veya Hizmetimizin işlevselliğini iyileştirmek veya bu verileri daha uzun süre saklamamıza yasal olarak zorlanırsak, bu verileri daha uzun süre saklayabiliriz.</p>

              <br/>
              <h3>Kişisel Verilerinizin Transferi</h3>

              <p>Bilgileriniz, Kişisel Veriler dahil, Şirketin işletme ofislerinde ve işleme dahil olan tarafların bulunduğu diğer yerlerde işlenir. Bu, bilgilerinizi, veri koruma yasalarının sizin yargı alanınızdan farklı olabileceği devlet, il, ülke veya başka bir hükümet yargı alanının dışındaki bilgisayarlara aktarabileceğimiz anlamına gelir.</p>
              <p>Bu Gizlilik Politikasına tabi olarak bu bilgilerin transferine onay vermiş olursunuz.</p>
              <p>Şirket, verilerinizin güvenli bir şekilde ve bu Gizlilik Politikası'na uygun olarak işlenmesini sağlamak için makul her türlü adımı atacaktır ve Kişisel Verileriniz, bu verilerinizin güvenliğini ve diğer kişisel bilgilerinizi korumak için uygun kontrollerin bulunmadığı bir organizasyona veya ülkeye Kişisel Verilerinizin transferi gerçekleşmeyecektir.</p>

              <br/>
              <h3>Kişisel Verilerinizi Silme</h3>

              <p>Sizinle ilgili olarak topladığımız Kişisel Verilerinizi silme veya silmemize yardımcı olma hakkına sahipsiniz.</p>
              <p>Hizmetimiz, Servis içindeki belirli bilgilerinizi silme yeteneği sağlayabilir.</p>
              <p>Hesabınıza giriş yaparak, eğer varsa, hesap ayarları bölümünü ziyaret ederek bilgilerinizi her zaman güncelleyebilir, düzeltebilir veya silebilirsiniz. Ayrıca, bize sağladığınız herhangi bir kişisel bilgiye erişim, düzeltme veya silme talebinde bulunmak için bize başvurabilirsiniz.</p>
              <p>Ancak, yasal bir yükümlülüğümüz veya meşru bir temelimiz olduğunda belirli bilgileri saklamamız gerekebileceğini lütfen unutmayın.</p>

              <br/>
              <h3>Kişisel Verilerinizin Açıklanması</h3>

              <br/>
              <h4>İşlemler</h4>

              <p>Eğer Şirket bir birleşme, satın alma veya varlık satışının içinde yer alıyorsa, Kişisel Verileriniz aktarılabilir. Kişisel Verilerinizin aktarılması ve farklı bir Gizlilik Politikasına tabi olması durumunda size bildirimde bulunulacaktır.</p>

              <br/>
              <h4>Hukuk Uygulama</h4>

              <p>Bazı durumlarda, Şirketin, yasal yükümlülüğe uygun olarak veya kamu otoriteleri (örneğin, bir mahkeme veya bir hükümet ajansı) tarafından geçerli taleplere yanıt olarak Kişisel Verilerinizi açıklama zorunda kalabileceği durumlar olabilir.</p>

              <br/>
              <h4>Diğer hukuki gereksinimler</h4>

              <p>Şirket, Kişisel Verilerinizi:</p>
              <ul>
                <li>Bir hukuki yükümlülüğe uyum sağlamak</li>
                <li>Şirketin haklarını veya malını korumak ve savunmak</li>
                <li>Hizmet ile ilgili olarak olası hatalı davranışları önlemek veya soruşturmak</li>
                <li>Hizmet kullanıcılarının veya genel halkın kişisel güvenliğini korumak</li>
                <li>Hukuki sorumluluktan korunmak amacıyla</li>
              </ul>
              <p>gibi iyi niyetle inanılıyorsa, Kişisel Verilerinizi açıklama hakkına sahip olabilir.</p>

              <br/>
              <h3>Kişisel Verilerinizin Güvenliği</h3>

              <p>Kişisel Verilerinizin güvenliği bizim için önemlidir, ancak internet üzerinden iletim veya elektronik depolama yönteminin %100 güvenli olmadığını unutmayın. Kişisel Verilerinizi korumak için ticari olarak kabul edilebilir yöntemleri kullanmaya çalışsak da, mutlak güvenliğini garanti edemeyiz.</p>

              <br/>
              <h2>Çocukların Gizliliği</h2>

              <p>Hizmetimiz 13 yaşın altındaki kişilere yönelik değildir. Bilerek 13 yaşın altındaki kişilerden kişisel tanımlanabilir bilgi toplamıyoruz. Eğer bir ebeveyn veya vasıfsız bir yetişkin olarak, çocuğunuzun bize Kişisel Veri sağladığını fark ederseniz, lütfen bizimle iletişime geçin. Eğer 13 yaşın altındaki birinden ebeveyn onayı olmadan Kişisel Veri topladığımızı fark edersek, bu bilgileri sunucularımızdan kaldırmak için adımlar atarız.</p>
              <p>Bilgilerinizi işlemenin yasal dayanağı olarak onayımıza güvenmemiz gerekiyorsa ve ülkeniz ebeveyn onayını gerektiriyorsa, bilgileri toplamadan ve kullanmadan önce ebeveyninizin onayını isteyebiliriz.</p>

              <br/>
              <h2>Diğer Web Sitelerine Bağlantılar</h2>

              <p>Hizmetimiz, tarafımızca işletilmeyen diğer web sitelerine bağlantılar içerebilir. Eğer üçüncü taraf bir bağlantıya tıklarsanız, o bağlantı sizi ilgili üçüncü tarafın sitesine yönlendirecektir. Her ziyaret ettiğiniz site için Gizlilik Politikasını incelemenizi şiddetle tavsiye ederiz.</p>
              <p>Üçüncü taraf sitelerin veya hizmetlerin içeriği, gizlilik politikaları veya uygulamaları üzerinde hiçbir kontrolümüz yoktur ve hiçbir sorumluluk üstlenmiyoruz.</p>

              <br/>
              <h2>Bu Gizlilik Politikasındaki Değişiklikler</h2>

              <p>Gizlilik Politikamızı zaman zaman güncelleyebiliriz. Herhangi bir değişiklik durumunda, yeni Gizlilik Politikasını bu sayfada yayınlayarak sizi bilgilendireceğiz.</p>
              <p>Değişikliklerin yürürlüğe girmesinden önce sizi e-posta veya Hizmetimizde belirgin bir bildirim aracılığıyla bilgilendirecek ve bu Gizlilik Politikasının en üstündeki "Son Güncelleme" tarihini güncelleyeceğiz.</p>
              <p>Herhangi bir değişiklik için bu Gizlilik Politikasını periyodik olarak gözden geçirmeniz önerilir. Bu Gizlilik Politikasındaki değişiklikler, bu sayfada yayınlandıkları anda yürürlüğe girer.</p>


              <br/>

              <h2>İletişim</h2>

              <p>
                Bu Gizlilik Politikası hakkında herhangi bir sorunuz varsa bizimle şu adresten iletişime geçebilirsiniz:
              </p>
              <p>
                <b>
                  support@kastrakomnen.com
                </b>
              </p>

              <br/>
              <br/>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default PrivacyPolicyTr;

