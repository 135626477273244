import React, {useEffect} from "react";
import './LoginPage.css';
import './LoginForm.css';

const LoginPage = () => {

  useEffect(() => {
    // Redirect to the authentication server URL
    window.location.href = "https://mark-1.auth.eu-central-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=3eb2b8ij4e2ojr3jiui6es2ahq&redirect_uri=https%3A%2F%2Fwww.gyropole.com%2Fcallback";
  }, []);

  return (
    <div></div>
  );

};

export default LoginPage